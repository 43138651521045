<template>
  <div class="flex h-screen fixed w-screen dark:bg-slate-800 pb-20 lg:pb-0">
    <div
      class="h-screen hidden md:hidden lg:block w-24 hover:w-[12rem] [&:hover_.item]:inline [&:hover_.item-wrapper]:w-36 [&:hover_.item-wrapper]:transition-all duration-300 transition-all"
    >
      <SideNavigation />
    </div>
    <div
      class="main flex-grow h-full flex flex-col items-center overflow-y-scroll overflow-x-hidden mb-32 lg:mb-0"
    >
      <div
        class="lg:flex justify-between items-center lg:px-4 md:px-2 px-8 w-full py-3 hidden"
      >
        <div class="hidden lg:block"></div>

        <!-- * Remove this if you want to add navigation bar-->

        <!-- <SearchInput
					v-slot="{ active, user, selected }"
					combobox-classes="absolute mt-4 left-1 lg:left-0 max-h-60 max-w-md min-w-[17rem] lg:min-w-[15rem] flex flex-col overflow-auto rounded-md bg-base-200 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50"
				>
					<a
						:href="'/' + user.name"
						target="_blank"
						class="relative flex gap-3 m-1 select-none items-center py-2 px-3 rounded-md cursor-pointer"
						:class="{
							'border-primary border text-neutral': active,
						}"
					>
						<div class="avatar">
							<div class="w-10 rounded-full">
								<img
									:src="user.image"
									:alt="`${user.name} profile image`"
								/>
							</div>
						</div>
						<div class="flex flex-col">
							<span
								class="block truncate"
								:class="{
									'font-medium': selected,
									'font-normal': !selected,
								}"
							>
								{{ user.name }}
							</span>
							<span
								class="block truncate text-xs"
								:class="{
									'font-medium': selected,
									'font-normal': !selected,
								}"
							>
								{{ user.full_name }}
							</span>
						</div>
					</a>
				</SearchInput> -->
        <routerLink to="/admin/account" class="lg:hidden mt-1">
          <div class="avatar">
            <div
              class="w-8 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2"
            >
              <img :src="user.image" alt="Tailwind-CSS-Avatar-component" />
            </div>
          </div>
        </routerLink>
        <!-- <RequestsPanel /> -->
        <!-- <div class="flex gap-2 items-center">
          <Button
            class="justify-self-end lg:block md:block min-w-fit hidden btn-sm"
            type="primary"
            @click="showShareModal = true"
          >
            <div class="flex gap-2 items-center">
              <icon name="material-symbols:share" />
              <span class="block">Share</span>
            </div>
          </Button>
        </div> -->
      </div>

      <div class="w-full min-h-fit px-2 p-1">
        <slot />
      </div>
    </div>

    <div class="lg:hidden md:fixed fixed bottom-3 w-full z-50">
      <BottomNavigation />
    </div>

    <ShareModal
      :show="showShareModal"
      @close="showShareModal = false"
      :user="user"
    />
  </div>
</template>
<script setup>
import { useUserStore } from "~/stores/user";

import { storeToRefs } from "pinia";
const showShareModal = ref(false);

const { user } = storeToRefs(useUserStore());
</script>

<style scoped></style>

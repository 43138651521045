<template>
  <aside
    class="flex flex-col h-[100%] items-center justify-between py-6 bg-base-100 shadow-lg bordered"
  >
    <div class="flex flex-col items-center space-y-20 w-full h-full">
      <div class="text-center flex flex-col items-center gap-5">
        <!-- <Logo /> -->
        <!-- <img src="~/assets/logo2.jpg" class="h-14 w-14" alt=""> -->

        <div class="avatar">
          <div class="w-16 rounded-full">
            <img :src="user.image" alt="" />
          </div>
        </div>
        <div class="item hidden text-sm break-words text-center">
          {{ user.name }}
        </div>
      </div>
      <div class="space-y-8 flex flex-col justify-between w-full h-full">
        <div class="flex flex-col items-center gap-6">
          <routerLink to="/admin/">
            <SideNavigationItem active="admin">
              <!-- <icon name="fluent:tag-ui-24-filled" size="30" /> -->
              <svg
                data-v-cf1ec82f=""
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                aria-hidden="true"
                role="img"
                class="icon"
                width="30px"
                height="30px"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M5.25 4A3.25 3.25 0 0 0 2 7.25v9.5A3.25 3.25 0 0 0 5.25 20h13.5A3.25 3.25 0 0 0 22 16.75v-9.5A3.25 3.25 0 0 0 18.75 4zM5 7.75A.75.75 0 0 1 5.75 7h5.5a.75.75 0 0 1 0 1.5h-5.5A.75.75 0 0 1 5 7.75M6 13h7a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1m-.25-3.5h10.5a.75.75 0 0 1 0 1.5H5.75a.75.75 0 0 1 0-1.5"
                ></path>
              </svg>
              <span
                class="item overflow-hidden hidden transition-all duration-300"
                >Bio</span
              >
            </SideNavigationItem>
          </routerLink>
          <routerLink to="/admin/account" class="">
            <SideNavigationItem
              :active="['admin-account', 'admin-account-refer-earn']"
            >
              <icon name="material-symbols:account-box" size="30" />
              <span
                class="dark:text-white item overflow-hidden hidden transition-all duration-300"
                >Account</span
              >
            </SideNavigationItem>
          </routerLink>
          <routerLink to="/admin/preview" class="lg:hidden">
            <SideNavigationItem active="admin-preview">
              <icon name="material-symbols:preview" size="30" />
              <span
                class="dark:text-white item overflow-hidden hidden transition-all duration-300"
                >Preview</span
              >
            </SideNavigationItem>
          </routerLink>
        </div>
        <Button
          type="danger"
          class="min-w-[2.5rem] btn-sm self-center"
          @click="deleteConfirm = true"
        >
          <div class="flex items-center justify-center gap-2">
            <icon class="block" name="oi:power-standby" />
            <span class="dark:text-white item hidden transition-all"
              >Logout</span
            >
          </div>
        </Button>
      </div>

      <ConfirmModal
        :show="deleteConfirm"
        title="Logout"
        body="Are you sure you want to logout"
        type="danger"
        @close="deleteConfirm = false"
        @confirm="logout()"
        positive="Logout"
      />
    </div>
  </aside>
</template>

<script setup>
import { useAuthStore } from "~/stores/auth";
import { useUserStore } from "~/stores/user";
import { storeToRefs } from "pinia";
const { user } = storeToRefs(useUserStore());
const authStore = useAuthStore();

const deleteConfirm = ref(false);

async function logout() {
  await authStore.logout();
  useRouter().push("/login");
}
</script>

<style scoped></style>
